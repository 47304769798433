import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material'
import { compareDesc, format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import AppBar from './AppBar'
import DeleteIcon from '@mui/icons-material/Delete'

const History = () => {
  const navigate = useNavigate()

  const handleRowClick = result => {
    navigate('/historyDetail', {state: result})
  }

  const handleClear = () => {
    localStorage.clear()
    window.location.reload()
  }

  return <>
    <AppBar title="履歴">
      <IconButton 
        size="large"
        onClick={handleClear}
        color="inherit"
      >
        <DeleteIcon /> 
      </IconButton>
    </AppBar>
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>日時</TableCell>
            <TableCell>バーコード</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(localStorage).sort((a, b) => compareDesc(new Date(a), new Date(b))).map(hist => {
            let scanResult = JSON.parse(localStorage.getItem(hist))
            return <TableRow key={hist} onClick={() => handleRowClick(scanResult)}>
              <TableCell>{format(new Date(hist), 'M/d h:m')}</TableCell>
              <TableCell>{scanResult.data.data}</TableCell>
            </TableRow>
          }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  </>
}

export default History
