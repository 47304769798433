import { Stack } from '@mui/material'
import { ResultTable } from './ResultTable'
import AppBar from './AppBar'
import { useLocation } from 'react-router-dom'

const HistoryDetail = () => {
  const { state } = useLocation()
  return <>
    <AppBar title="バーコード詳細" />
    <Stack>
      <ResultTable data={state.data} image={state.image}/>
    </Stack>
  </>
}

export default HistoryDetail
