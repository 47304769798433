import { AppBar, IconButton, Toolbar, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'

const HistoryAppBar = ({ title, children }) => {
  const navigate = useNavigate()

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <AppBar position="sticky" color='primary' >
      <Toolbar>
        <IconButton
          size="large"
          onClick={handleBack}
          color="inherit"
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography sx={{ flexGrow: 1, textAlign: 'center', fontWeight: 500 }}>{title}</Typography>
        {children}
      </Toolbar>
    </AppBar>
  )
}

export default HistoryAppBar
