import axios from 'axios'

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 30000
})

export const decode2url = (imagebase64, formats, requestId) => {
  return client.post('decode2url', {imagebase64, formats, requestId})
}

export const decode = (file, formats, requestId) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('formats', formats)
  formData.append('requestId', requestId)
  return client.postForm('decode', formData)
}
