
export function base64ToBlob(base64String) {
  const parts = base64String.split(';base64,')
  const contentType = parts[0].split(':')[1]
  const raw = window.atob(parts[1])
  const rawLength = raw.length
  const uInt8Array = new Uint8Array(rawLength)
  for (let i = 0; i < rawLength; ++i) 
    uInt8Array[i] = raw.charCodeAt(i)
  
  return new Blob([uInt8Array], { type: contentType })
}
