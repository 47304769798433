export const dummyData = {
  '04987138806291': [
    {
      'name': 'ツムラ防風通聖散エキス顆粒（医療用）',
      'url': 'https://www.rad-ar.or.jp/siori/search/result?n=8883'
    }
  ],
  '04987138802996': [
    {
      'name': 'ツムラ麦門冬湯エキス顆粒（医療用）',
      'url': 'https://www.rad-ar.or.jp/siori/search/result?n=8238'
    }
  ],
  '04987123564861': [
    {
      'name': 'ヒアルロン酸Na点眼液0.1%「センジュ」',
      'url': 'https://www.rad-ar.or.jp/siori/search/result?n=48648'
    },
    {
      'name': 'ヒアルロン酸Na点眼液0.3%「センジュ」',
      'url': 'https://www.rad-ar.or.jp/siori/search/result?n=48649'
    }
  ],
  '04987233740964': [
    {
      'name': 'イリボー錠2.5μg',
      'url': 'https://www.rad-ar.or.jp/siori/search/result?n=13937'
    },
    {
      'name': 'イリボー錠5μg',
      'url': 'https://www.rad-ar.or.jp/siori/search/result?n=13938'
    }
  ],
  '04987117630046': [
    {
      'name': 'ビラノア錠20mg(Ｍｅｉｊｉ　Ｓｅｉｋａ　ファルマ株式会社)',
      'url': 'https://www.rad-ar.or.jp/siori/search/result?n=43006'
    },
    {
      'name': 'ビラノア錠20mg(大鵬薬品工業株式会社)',
      'url': 'https://www.rad-ar.or.jp/siori/search/result?n=42950'
    }
  ]
}
