import { useLocation } from 'react-router-dom'
import AppBar from './AppBar'
import { Box, Stack } from '@mui/material'

const PmdaSite = () => {
  const location = useLocation()
  const url = location.state?.url
  return <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
    <AppBar title="関連文書" >
      <span style={{ marginRight: '40px' }}></span>
    </AppBar>
    <iframe style={{ width: '100%', flexGrow: 1, border: 'none' }} src={url} title="関連文書" />
  </Box>
}

export default PmdaSite
