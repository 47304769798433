import { AppBar, Button, Toolbar } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { getTime } from 'date-fns'
import { useCallback, useEffect, useRef, useState } from 'react'
import { decode } from './Api'
import { ResultTable } from './ResultTable'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import { useNavigate } from 'react-router-dom'
import { base64ToBlob } from './util'

const gs1Formats = [
  'CODE_128',
  'DATA_MATRIX',
  'DataBar',
  'DataBarLimited',
  // 'MicroPDF417'
]

const standFormats = [
  'CODE_39',
  'CODE_93',
  'CODABAR',
  'EAN_13',
  'EAN_8',
  'ITF',
  'UPC_A',
  'UPC_E',
  'DataBarExpanded',
  'QR_CODE',
  'AZTEC',
  'MAXICODE',
]

const Barcode = () => {
  const intervalRef = useRef()
  const requests = useRef({})
  const [mediaStream, setMediaStream] = useState()
  const [data, setData] = useState()
  const [capture, setCapture] = useState(false)
  const [size, setSize] = useState('640x480')
  const navigate = useNavigate()
  const [videoConstraints, setVideoConstraints] = useState({
    width: 480,
    height: 640,
    aspectRatio: 0.55,
    facingMode: 'environment',
    focusMode: 'continuous',
  })

  useEffect(() => {
    startMediaStream()

    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])


  const handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden')
      stopCapture()
  }

  useEffect(() => {
    const sizes = size.split('x')
    setVideoConstraints(Object.assign({}, videoConstraints, {
      width: sizes[1],
      height: sizes[0]
    }))

    if (mediaStream) {
      stopMediaStream(mediaStream)
      startMediaStream()
    }

  }, [size])


  const handleBarcode = (requestId, data) => {
    if (data)
      if (data.code === 100)
        stopCapture(requestId, data)

    delete requests.current[requestId]
  }

  const takePhoto = () => {
    const video = document.querySelector('video')
    let image = drawImage(getCanvas(video), video)
    let requestId = getTime(new Date())

    requests.current = { ...requests.current, ...{ [requestId]: { image } } }
    decode(base64ToBlob(image), [...gs1Formats, ...standFormats], requestId).then(({ data }) => {
      console.log(data)
      handleBarcode(requestId, data)
    })
  }

  const getCanvas = video => {
    let canvas = document.querySelector('canvas')

    const sizes = size.split('x')
    let aspectRatio = video.videoWidth / video.videoHeight

    let canvasWidth = sizes[0]
    let canvasHeight = sizes[1]

    canvas.width = canvasWidth
    canvas.height = canvasHeight

    return canvas
  }

  const startMediaStream = () => {
    navigator.mediaDevices.getUserMedia({ audio: false, video: videoConstraints })
      .then((mediaStream) => {
        const video = document.querySelector('video')
        video.setAttribute('playsinline', true)
        video.srcObject = mediaStream
        setMediaStream(mediaStream)
        startCapture()
        video.onloadedmetadata = () => {
          video.play()
        }
      })
      .catch((err) => {
        // always check for errors at the end.
        console.error(`${err.name}: ${err.message}`)
      })
  }

  const stopMediaStream = function (stream) {
    if (stream)
      if (stream.getVideoTracks && stream.getAudioTracks) {
        stream.getVideoTracks().map(function (track) {
          stream.removeTrack(track)
          track.stop()
        })
        stream.getAudioTracks().map(function (track) {
          stream.removeTrack(track)
          track.stop()
        })
      }
      else
        stream.stop()
  }

  const drawImage = (canvas, image) => {
    let ctx = canvas.getContext('2d')
    ctx.imageSmoothingEnabled = false
    ctx.save()
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
    ctx.restore()
    return canvas.toDataURL('image/jpeg', 1)
  }

  const startCapture = useCallback(async () => {
    if (capture) return
    const video = document.querySelector('video')
    await video.play()
    setCapture(true)
    setData(undefined)
    intervalRef.current = setInterval(takePhoto, 250)
  })


  const stopCapture = useCallback((requestId, data) => {
    const video = document.querySelector('video')
    video.pause()
    setCapture(false)
    clearInterval(intervalRef.current)

    if (requests.current[requestId]) {
      setData(data)

      var img = new Image()
      img.onload = () => drawImage(document.querySelector('canvas'), img)
      img.src = requests.current[requestId].image

      requests.current = {}
    }
  })

  return <Stack spacing={2} alignItems="center">

    <AppBar position="static" color={capture ? 'secondary' : 'primary'} >
      <Toolbar>
        <Box sx={{ flexGrow: 1, textAlign: 'center', fontWeight: 500 }} >くすりのしおり</Box>
      </Toolbar>
    </AppBar>
    <Stack sx={{ height: '220px', width: '100%', overflow: 'hidden', alignItems: 'center' }} >
      <video autoPlay style={{ width: '92%', display: data ? 'none' : 'block', objectFit: 'fill' }} />
      <canvas style={{ width: '92%', display: data ? 'block' : 'none' }} />
    </Stack>
    {
      capture ?
        <Button sx={{ width: '40%' }} startIcon={<CameraAltIcon />} onClick={stopCapture} variant="contained" size="large" color="error">Stop</Button> :
        <Button sx={{ width: '40%' }} startIcon={<CameraAltIcon />} onClick={startCapture} variant="contained" size="large" color="success">Start</Button>
    }
    <ResultTable data={data || {}} />
  </Stack>
}

export default Barcode
