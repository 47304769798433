import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Barcode from './Barcode'
import History from './History'
import HistoryDetail from './HistoryDetail'
import PmdaSite from './PmdaSite'

class App extends Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Barcode />} />
          <Route path="/pmda" element={<PmdaSite />} />
          <Route path="/history" element={<History />} />
          <Route path="/historyDetail" element={<HistoryDetail />} />
        </Routes>
      </Router>
    )
  }
}

export default App

