import Grid from '@mui/material/Unstable_Grid2'
import { Typography } from '@mui/material'
import { dummyData } from './dummyData'

export function ResultTable(props) {
  let { data, gtin, url, format } = props.data

  const demoGtin = dummyData[gtin]
  console.log(demoGtin)
  const demoContent = demoGtin?.map(v =>
    <p style={{ marginTop: 0, marginBottom: '10px', fontSize:'14px' }}><a key={v.url} href={v.url} target='_blank'>{v.name}</a></p>
  )

  return (
    <Grid
      container
      mx={1}
      spacing={2}
      sx={{
        '--Grid-borderWidth': '1px',
        borderTop: 'var(--Grid-borderWidth) solid',
        borderLeft: 'var(--Grid-borderWidth) solid',
        borderColor: 'divider',
        '& > div': {
          borderRight: 'var(--Grid-borderWidth) solid',
          borderBottom: 'var(--Grid-borderWidth) solid',
          borderColor: 'divider',
        },
        width: '95%'
      }}
    >
      <Grid xs={4} sx={{ backgroundColor: '#eee' }}>
        <Typography variant="subtitle1">
          タイプ
        </Typography>
      </Grid>
      <Grid xs={8} sx={{ wordWrap: 'break-word' }}>
        <Typography variant="body1">
          {format}
        </Typography>
      </Grid>
      <Grid xs={4} sx={{ backgroundColor: '#eee' }}>
        <Typography variant="substring1">
          商品コード<br />(GTIN)
        </Typography>
      </Grid>
      <Grid xs={8}>
        <Typography variant="body1" sx={{ wordWrap: 'break-word' }}>
          {data}
        </Typography>
      </Grid>
      <Grid xs={4} sx={{ backgroundColor: '#eee' }}>
        <Typography variant="subtitle1">
          薬のしおり
        </Typography>
      </Grid>
      <Grid xs={8} sx={{ wordWrap: 'break-word' }}>
        <Typography variant="body1">
          {demoGtin && demoContent}
          {!demoGtin && url && <a href={url} target='_blank'>関連文書</a>}
        </Typography>
      </Grid>
    </Grid>
  )
}
